import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';
import './App.css';

import LoadingWrapper from "./ModulePublic/loader/LoadingWrapper";
import Header from "./SiteComponent/header/header";
import Footer from "./SiteComponent/footer/footer";

function App() {
  return (

    <Router>
      <div className={`min-h-full w-full flex items-center flex-col bg-gray-50`}>
        <LoadingWrapper>
          <Header />
          <AnimatedRoutes />
          <Footer />
        </LoadingWrapper>
      </div>
    </Router>

  );
}

export default App;