// Reviews.js
import React, { useState, useEffect } from 'react';

import './kassi.css';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as Logo1 } from './img/o1.svg';
import { ReactComponent as Logo2 } from './img/o2.svg';
import { ReactComponent as Logo3 } from './img/o3.svg';
import { ReactComponent as Logo4 } from './img/o4.svg';
import { ReactComponent as Logo5 } from './img/o5.svg';

const Kkt = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  const formMotion = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } }
  };

  const [kkts, setKkts] = useState(null);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    const fetchKkts = async () => {
      try {
        const response = await fetch(`https://lk.eq74.ru/api/kkt/kktall`);
        if (response.ok) {
          const data = await response.json();
          // Для безопасности мы проверяем, является ли data.reviews массивом, прежде чем задавать его в качестве состояния.
          if (Array.isArray(data)) {
            setKkts(data);
          } else {
            console.error('Некорректный формат данных:', data);
          }
        } else {
          console.error('Ошибка при получении услуг ккт');
        }
      } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
      }
    };
    fetchKkts();
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Предотвращаем стандартное поведение формы

    // Собираем данные из формы
    const formData = {
      nameZay: event.target.nameZay.value,
      mailZay: event.target.mailZay.value,
      phoneZay: event.target.phoneZay.value,
      woprosZay: event.target.woprosZay.value,
      statusZay: 'new',
      vidZay: 'kkt',
    };


    // Отправляем данные на сервер
    try {
      const response = await fetch('https://lk.eq74.ru/api/kkt/addzayavkissayta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Заявка успешно отправлена:', result);
        setIsSubmitted(true);
        // Обработка успешной отправки (например, очистка формы или сообщение пользователю)
      } else {
        console.error('Ошибка при отправке заявки:', response.statusText);
        // Обработка ошибки отправки
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      // Обработка ошибки запроса
    }
  };

  const handleFormButtonClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  if (!kkts) {
    return <div>Loading...</div>;
  }
  const hasKkts = Array.isArray(kkts) && kkts.length > 0;

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className='bodykassi'>
        <div className="header-cont" data-slick-index="0" aria-hidden="false">
          <div className="container">
            <h1>Все для работы кассы<br /> по 54-ФЗ от 2000 ₽</h1>
            <button onClick={handleFormButtonClick}>
              Оставить заявку
            </button>
          </div>
        </div>

        <div className="block-margin">
          <div className="container-text">
            <h2><span>Важная информация о 54-ФЗ</span></h2>
            <ul className="list-fz14">
              <li>Данные о продажах с каждого выбитого чека должны передаваться в налоговую через интернет и оператора фискальных данных, с которым предприниматель заключил договор</li>
              <li>Выдача бумажного чека осталась обязательной. Дополнительно, по желанию покупателя, нужно обеспечить возможность отправки чека по email или sms</li>
              <li>Все кассовые аппараты должны содержать фискальный накопитель (ФН) вместо ЭКЛЗ. Кассы старого образца должны быть заменены или модернизированы</li>
              <li>Упростилась процедура регистрации кассового аппарата, зарегистрировать онлайн-кассу в налоговой можно удаленно</li>
              <li>Изменились требования к самим касовым чекам и бланкам строгой отчетности. Количество данных, которые на них должны обязательно присутствовать, возросло</li>
              <li>Предпринимателям, работающим на Патенте и ЕНВД нужно передавать данные с каждой продажи в налоговую. С 1 июля 2018 года применение касс для них становится обязательным</li>
            </ul>
          </div>
        </div>

        <div className='margin-top-conteiner'>
          <div className="container-usl">
            <h2><span>Что нужно для кассы по 54-ФЗ</span></h2>
            <div className='row'>

              {hasKkts ? (
                kkts.map((kkt) => (
                  <motion.div key={kkt._id} initial="out" animate="in" exit="out" variants={pageMotion}>
                    <div className='card-usl-conteiner'>
                      <div className='card-usl'>
                        <div className='card-usl-name'>
                          <h3>{kkt.nameKkt}</h3>
                        </div>
                        <div className='card-usl-opisanie'>
                          <span><p>{kkt.opisanie}</p></span>
                        </div>
                        <div className='card-cena-button'>
                          <span className='card-cena'><h4>{kkt.cenaKkt} руб.</h4></span>
                          <button>
                            Заказать
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <p>Нет услуг по ККТ.</p>
              )}

            </div>
          </div>
        </div>

        <div className="margin-top-conteiner">
          <div className="container">
            <h2><span>Наши преимущества</span></h2>
            <div className="justify-content-between-row">
              <div className="col-md-auto-col-6">
                <div className="iconblock__item">
                  <div className='imgico'>
                    <Logo1 />
                  </div>
                  <p><b>Комплексное<br /> решение</b></p>
                  <p className="small">Подберем тариф ОФД,<br /> фискальный накопитель<br /> и подпись</p>
                </div>
              </div>
              <div className="col-md-auto-col-6">
                <div className="iconblock__item">
                  <div className='imgico'>
                    <Logo2 />
                  </div>
                  <p><b>Подбор<br /> тарифа</b></p>
                  <p className="small">Поможем подключить <br />кассу и настроим <br />передачу данных в ФНС</p>
                </div>
              </div>
              <div className="col-md-auto-col-6">
                <div className="iconblock__item">
                  <div className='imgico'>
                    <Logo3 />
                  </div>
                  <p><b>Безопасность<br /> данных</b></p>
                  <p className="small">Все сервисы и услуги отвечают<br /> последним требованиям ФСБ <br />о защите данных</p>
                </div>
              </div>
              <div className="col-md-auto-col-6">
                <div className="iconblock__item">
                  <div className='imgico'>
                    <Logo4 />
                  </div>
                  <p><b>Помощь <br />в настройке</b></p>
                  <p className="small">Вы можете выбрать как бюджетное <br />решение, так и полный комплект <br />для работы с кассой</p>
                </div>
              </div>
              <div className="col-md-auto-col-6">
                <div className="iconblock__item">
                  <div className='imgico'>
                    <Logo5 />
                  </div>
                  <p><b>Соответствие<br /> 54-ФЗ</b></p>
                  <p className="small">Мы предлагаем только те услуги, <br />которые соответствуют требованиям <br />закона и рынка</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="formBot" className="block-bgForm">
          <div className="align-items-center-row">
            <div className="col-md-6-form">
              <AnimatePresence mode='wait'>
                {isSubmitted ? (
                  <motion.div key="successMessage" initial="initial" animate="animate" exit="exit" variants={formMotion}>
                    <div className='okForm'>
                      <h2>Спасибо за вашу заявку!</h2>
                      <p>Мы получили вашу заявку и скоро свяжемся с вами.</p>
                    </div>
                  </motion.div>
                ) : (
                  // Если форма не отправлена, показываем саму форму
                  <motion.div key="form" initial="initial" animate="animate" exit="exit" variants={formMotion}>
                    <h2 className="mt-3"><span>Бесплатно проконсультируем по вопросам кассы</span></h2>
                    <p>Оставьте заявку, и менеджер свяжется с Вами</p>
                    <form className="whiteForm-send_pop_form" onSubmit={handleSubmit} data-form_contacts="some-feedback-form" tariff="Заказать консультацию">
                      <input className='input-form' type="text" name="nameZay" placeholder="Имя" required="" autoComplete="off" />
                      <input className='input-form' type="text" name="mailZay" placeholder="E-mail" required="" autoComplete="off" im-insert="true" />
                      <input className='input-form' type="text" name="phoneZay" placeholder="Телефон" required="" autoComplete="off" im-insert="true" />
                      <input className='input-form' type="text" name="woprosZay" placeholder="Ваш вопрос" autoComplete="off" />
                      <button type="submit" disabled={!isChecked}>Отправить</button>
                      <label className="consent">
                        <input className='input-check' type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                        <p>Я даю согласие на обработку моих <a href="/privacy">персональных данных</a></p>
                      </label>
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="col-md-img">
            </div>
          </div>
        </div>

      </div>
    </motion.div>
  );
};

export default Kkt;