import React from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../../icon.svg';
import { FaPhone, FaMailBulk } from "react-icons/fa";
import Navigation from "../navigation/navigation";

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className="flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap p-5 gap-10">
            <div className="flex relative items-center justify-center flex-wrap">
                <div className="flex cursor-pointer justify-center items-center" onClick={() => navigate("/")}>
                    <img src={logo} className="h-32 w-32" alt="logo" onClick={() => navigate("/")} />
                </div>
                <div className="flex relative flex-col">
                    <h2 className="h1 font-bold text-2xl text-center">
                        Группа компаний "Принт-Сервис"
                    </h2>
                    <div className="flex relative border-b-2 border-gray-500 my-2 "></div>
                    <h2 className="h1 font-bold text-2xl text-center">
                        ООО "Инжиниринговая компания"
                    </h2>
                </div>
            </div>

            <div className="ml-auto flex items-center gap-5 flex-col">
                <div className="mr-4 flex flex-row items-center justify-center gap-5 flex-wrap">
                    <div className="flex items-center justify-center">
                        <FaPhone className="size-6 mr-2" />
                        <p>
                            +7 (351) 217-33-37
                        </p>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <FaMailBulk className="size-6 mr-2" />
                        <div className="flex flex-col">
                            <p>
                                info@sc-7.com
                            </p>
                            <p>
                                eq174@yandex.ru
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex relative flex-1">
                    <Navigation />
                </div>
            </div>
        </div>
    );
};

export default Header;
