// page/Contact.js
import React from 'react';
import { motion } from 'framer-motion';

const Otziv = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, minWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };
  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex relative min-h-30 w-full flex-col justify-center items-center gap-10">
        <div className='flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap bg-gray-700 gap-10 p-10'>
          <div className="flex relative w-60 self-center">
            <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://cdn1.iconfinder.com/data/icons/book_mac/512/Address_Book_Alt_red.png" />
          </div>
          <div className="flex relative flex-1 flex-col text-slate-50 items-center justify-center text-center flex-wrap gap-5">
            <div className="flex relative flex-1 text-center text-4xl">
              ОТЗЫВЫ О НАС
            </div>
            <div className="flex relative flex-1 text-center items-center justify-center text-sm">
              Отзывы клиентов: узнайте, почему наши клиенты выбирают нас! Ознакомьтесь с отзывами наших довольных клиентов и узнайте, как мы помогаем им достигать успеха.
            </div>
          </div>
        </div>
        <div className='flex relative w-full p-10 gap-4 flex-row flex-wrap justify-around'>
          <div className="w-[560px] h-[800px] relative">
            <iframe className="w-full h-full border-slate-950" src="https://yandex.ru/maps-reviews-widget/77919933697?comments">
            </iframe>
            <a href="https://yandex.ru/maps/org/print_servis/77919933697/" target="_blank" className="text-sm absolute w-full text-center hidden text-ellipsis max-h-[14px] flex-nowrap p-0">
              Принт-сервис на карте Челябинска — Яндекс Карты
            </a>
          </div>

          <div className="w-[560px] h-[800px] relative">
            <iframe className="w-full h-full border-slate-950" src="https://yandex.ru/maps-reviews-widget/12758637858?comments">
            </iframe>
            <a href="https://yandex.ru/maps/org/inzhiniringovaya_kompaniya/12758637858/" target="_blank" className="text-sm absolute w-full text-center hidden text-ellipsis max-h-[14px] flex-nowrap p-0">
              Инжиниринговая компания на карте Челябинска — Яндекс Карты
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  )
};

export default Otziv;