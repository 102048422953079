// page/Contact.js
import React from 'react';
import { motion } from 'framer-motion';

const Onas = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, minWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };
  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex relative min-h-30 w-full flex-col justify-center items-center gap-10">
        <div className='flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap bg-gray-700 gap-10 p-10'>
          <div className="flex relative w-60 self-center">
            <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://forzamk.crmgu.ru/wp-content/uploads/2018/07/icon_map.png" />
          </div>
          <div className="flex relative flex-1 flex-col text-slate-50 items-center justify-center text-center flex-wrap gap-5">
            <div className="flex relative flex-1 text-center text-4xl">
              НАША КОМАНДА
            </div>
            <div className="flex relative flex-1 text-center items-center justify-center text-sm">
              Узнайте о структуре и составе нашей группы компаний на одной странице
            </div>
          </div>
        </div>

        <div className='flex relative w-full min-h-60 flex-col md:p-10'>
          <div className='flex relative flex-wrap w-full min-w-96 min-h-60 h-full flex-row gap-10 bg-slate-100 shadow-xl'>
            <div className='flex relative w-96 min-h-60 flex-col bg-slate-700 p-10 text-slate-100 text-2xl justify-center items-center text-center'>
              ООО <br /> "Инжиниринговая компания"
            </div>
            <div className='flex relative flex-1 w-full flex-col'>
              <tbody className='divide-y '>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>ИНН</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>7448255029</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>КПП</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>744801001</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>ОГРН</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>1237400046608</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Юридический адрес</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>454112, Челябинская область, г Челябинск, пр-кт Победы, д. 290, офис 107</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Фактический адрес</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>454112, Челябинская область, г Челябинск, пр-кт Победы, д. 290, офис 107</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Почтовый адрес</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>454112, Челябинская область, г Челябинск, пр-кт Победы, д. 290, офис 107</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 border-none hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Директор</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>Бородина К.В.</td>
                  </tr>
              </tbody>
            </div>
          </div>
        </div>

        <div className='flex relative w-full min-h-60 flex-col md:p-10'>
          <div className='flex relative flex-wrap w-full min-w-96 min-h-60 h-full flex-row gap-10 bg-slate-100 shadow-xl'>
            <div className='flex relative w-96 min-h-60 flex-col bg-slate-700 p-10 text-slate-100 text-2xl justify-center items-center text-center'>
              ООО <br />СК "Принт-сервис"
            </div>
            <div className='flex relative flex-1 w-full flex-col'>
              <tbody className='divide-y '>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>ИНН</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>7460023850</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>КПП</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>744801001</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>ОГРН</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>1157460004833</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Юридический адрес</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>454138, Челябинская обл, Челябинск г, Чайковского ул, дом 2А</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Фактический адрес</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>454112, Челябинская область, г Челябинск, пр-кт Победы, д. 290, офис 105</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Почтовый адрес</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>454112, Челябинская область, г Челябинск, пр-кт Победы, д. 290, офис 105</td>
                  </tr>
                  <tr className='transition duration-150 ease-in-out hover:border-primary-600 border-none hover:bg-slate-200 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0'>
                    <td className='px-6 py-4 whitespace-nowrap text-lg font-medium text-slate-900 border-none text-right'>Директор</td>
                    <td className='px-6 py-4 whitespace-nowrap text-lg text-slate-900 border-none text-left'>Арутюнова О.С.</td>
                  </tr>
              </tbody>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
};

export default Onas;