import React, { useEffect, useState } from 'react';
import LoadingScreen from "./LoadingScreen";

const LoadingWrapper = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Завершите первоначальную загрузку через 2 секунды (для демонстрационных целей)
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? <LoadingScreen /> : children}
    </>
  );
};

export default LoadingWrapper;