import React from 'react';
import EquipmentCard from './EquipmentCard';
import { motion } from 'framer-motion';

const equipmentList = [
    {
        name: "Частотные преобразователи",
        description: "Преобразователи для регулирования частоты вращения двигателя.",
        imageUrl: "https://m-gen.ru/800/600/http/oksimetr.ru/wp-content/uploads/2/8/0/28043b65c2b60ee22ddd8c028241ea64.jpeg"
    },
    {
        name: "Устройства плавного пуска УПП",
        description: "Устройства для снижения пусковых токов и обеспечения плавного запуска.",
        imageUrl: "https://i1.wp.com/electrikmaster.ru/wp-content/uploads/2018/02/Ustroystvo-plavnogo-puska-9.png"
    },
    {
        name: "Приводы преобразователи постоянного тока",
        description: "Устройства для управления скоростью и крутящим моментом двигателей постоянного тока.",
        imageUrl: "https://m-gen.ru/800/600/http/ttaars.ru/upload/iblock/ab2/ab2725adde69888e71707b635e4e8292.jpg"
    },
    {
        name: "Сервоприводы (серводрайверы)",
        description: "Высокоточные устройства для управления положением механических компонентов.",
        imageUrl: "https://img01.flagma.by/photo/servodvigateli-servoprivod-4864019_big.jpg"
    },
    {
        name: "Блоки питания",
        description: "Устройства для обеспечения стабильного и надежного источника питания.",
        imageUrl: "https://static.orient-prices.ru/APfvWFcDcA14f_noohDzBQRNAa5VgptV1XrkBqO3dlg/f:webp/aHR0cDovL2ltYWdlcy9jMS85YS8zYi82ZC8yZTcyMWE3MS53ZWJw"
    },
    {
        name: "Платы управления",
        description: "Электронные компоненты, предназначенные для управления работой различных устройств.",
        imageUrl: "https://www.informteh.ru/upload/iblock/0c4/0c4c2ebe95788227189f67f9efc6025b.JPG"
    },
    {
        name: "Программируемые логические контроллеры ПЛК",
        description: "Устройства для автоматизации технологических процессов в промышленности.",
        imageUrl: "https://electro-scooterz.ru/wp-content/uploads/4/6/f/46f0f2bc0bc9155004943f9af7f495c4.jpeg"
    },
    {
        name: "Промышленные контроллеры и компьютеры",
        description: "Специализированные вычислительные устройства для промышленных приложений.",
        imageUrl: "https://www.icpdas.com/web/product/product_Image/png/pac/minios7/iP-8000/iP-8411/iP-8411-G_la02.png"
    },
    {
        name: "Сенсорные панели оператора HMI",
        description: "Интерфейсы для взаимодействия оператора с машинами и процессами.",
        imageUrl: "https://novosib.techintegra.ru/upload/iblock/7f0/7f002f7cbf0217099f86824ce14a58e3.jpg"
    },
    {
        name: "Инверторы и стабилизаторы напряжения",
        description: "Устройства для преобразования и стабилизации напряжения в электрических сетях.",
        imageUrl: "https://static.tildacdn.com/tild3639-3733-4537-a162-393365653633/5555.jpg"
    },
    {
        name: "Системы ЧПУ",
        description: "Компьютерные системы для автоматического управления станками и оборудованием.",
        imageUrl: "https://metalloobrabotka.spb-78.ru/attachments/Image/Proking_VS_35-1506441205-1.jpg?template=generic"
    },
    {
        name: "Источники бесперебойного питания и аварийного освещения",
        description: "Устройства для обеспечения непрерывности питания и освещения в аварийных ситуациях.",
        imageUrl: "https://www.gradsb.ru/upload/iblock/b4e/b4e0b6f9193f031dbcee0effa4d3ac25.jpeg"
    }
];

const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
};

function App() {
    return (
        <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>

            <div className='flex flex-col w-full min-h-screen gap-4'>
                <div className='flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap bg-gray-700 gap-10 p-10'>
                    <div className="flex relative w-60 self-center">
                        <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://i.pinimg.com/originals/78/7a/ea/787aea9a7684495d67b9188a9c69413b.png" />
                    </div>
                    <div className="flex relative flex-1 flex-col text-slate-50 items-center justify-center text-center flex-wrap gap-5">
                        <div className="flex relative flex-1 text-center text-4xl">
                            Ремонт промэлектроники и ЧПУ
                        </div>
                        <div className="flex relative flex-1 text-center items-center justify-center text-sm">
                            Профессиональный ремонт и обслуживание промышленной электроники и систем ЧПУ. Быстро, качественно и с гарантией!
                        </div>
                    </div>
                </div>
                <div className="p-8 min-h-screen">
                    <h1 className="text-3xl font-extrabold text-center mb-12 text-gray-900">
                        Информация о ремонте оборудования
                    </h1>
                    <div className="flex flex-wrap justify-center gap-8">
                        {equipmentList.map((item, index) => (
                            <EquipmentCard key={index} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default App;
