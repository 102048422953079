import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import ReactLoading from "react-loading";

const Contacts = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, minWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [remorgs, setRemOrgs] = useState(null);
  const targetRef = useRef(null);

  useEffect(() => {
    const fetchRemOrgs = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://lk.eq74.ru/api/remorg/remorgall');
        if (response.ok) {
          const data = await response.json();
          await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
          if (Array.isArray(data)) {
            setRemOrgs(data);
          } else {
            console.error('Некорректный формат данных:', data);
          }
        } else {
          console.error('Ошибка при получении данных');
        }
      } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRemOrgs();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const hasRemOrgs = Array.isArray(remorgs) && remorgs.length > 0;

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex w-full flex-col justify-center items-center gap-10">
        <div className='min-h-40 w-full flex items-center justify-between flex-wrap bg-gray-700 gap-10 p-10' ref={targetRef}>
          <div className="w-60 self-center">
            <img alt="Moneyback" src="https://www.pngall.com/wp-content/uploads/2016/04/Printer-Free-PNG-Image.png" width="142" height="134" />
          </div>
          <div className="flex flex-1 flex-col text-slate-50 items-center justify-center text-center gap-5">
            <div className="text-4xl">
              РЕМОНТ ПЕЧАТНОЙ ТЕХНИКИ
            </div>
            <div className="text-sm">
              Диагностика, обслуживание и восстановление работоспособности различных видов офисной техники, таких как принтеры, копировальные аппараты, компьютеры, сканеры и прочее оборудование. Сервисное обслуживание обеспечивает бесперебойную работу техники, исключает непредвиденные расходы на ремонт и гарантирует надежность и долговечность устройств.
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center min-h-full w-full flex-col">
            <ReactLoading type="spinningBubbles" color="#103d77" height={100} width={100} />
            <h2>Загрузка, пожалуйста подождите...</h2>
          </div>
        ) : (
          <>
            <div className='flex flex-col items-center justify-center w-full gap-6 p-6'>
              <h2 className='text-3xl font-semibold text-gray-900 mb-6'>
                Что входит в категории ремонта:
              </h2>
              <div className='flex w-full gap-6 flex-wrap justify-center'>
                <div className='flex flex-col bg-white shadow-md rounded-lg transition-transform transform hover:scale-105 p-6 max-w-md w-full'>
                  <h3 className='text-lg font-semibold text-gray-800 mb-4'>Ремонт 1 категории включает:</h3>
                  <ul className='list-disc list-inside text-gray-700 text-sm space-y-2'>
                    <li>Диагностика (контроль технического состояния)</li>
                    <li>Профилактика</li>
                    <li>Замена роликов захвата бумаги</li>
                  </ul>
                </div>
                <div className='flex flex-col bg-white shadow-md rounded-lg transition-transform transform hover:scale-105 p-6 max-w-md w-full'>
                  <h3 className='text-lg font-semibold text-gray-800 mb-4'>Ремонт 2 категории включает:</h3>
                  <ul className='list-disc list-inside text-gray-700 text-sm space-y-2'>
                    <li>Расширенная профилактика с полным разбором устройства</li>
                    <li>Очистка оптики, тракта прохождения бумаги</li>
                    <li>Ремонт термоузла</li>
                    <li>Замена шлейфов</li>
                    <li>Ремонт редуктора</li>
                    <li>Замена печатающих головок</li>
                  </ul>
                </div>
                <div className='flex flex-col bg-white shadow-md rounded-lg transition-transform transform hover:scale-105 p-6 max-w-md w-full'>
                  <h3 className='text-lg font-semibold text-gray-800 mb-4'>Ремонт 3 категории включает:</h3>
                  <ul className='list-disc list-inside text-gray-700 text-sm space-y-2'>
                    <li>Ремонт плат электроники</li>
                    <li>Ремонт блока питания</li>
                    <li>Замена плат и электронных компонентов</li>
                    <li>Перепрограммирование</li>
                    <li>Ремонт узла переноса изображения</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='flex flex-col items-center justify-center w-full gap-6 p-6'>
              {hasRemOrgs ? (
                <div className='w-full max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-x-auto'>
                  <table className='w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-800 text-white'>
                      <tr className='text-xs font-medium uppercase tracking-wider'>
                        <th scope='col' className='px-6 py-3 text-left'>
                          Наименование
                        </th>
                        <th scope='col' className='px-6 py-3 text-center'>
                          1 категория (руб.)
                        </th>
                        <th scope='col' className='px-6 py-3 text-center'>
                          2 категория (руб.)
                        </th>
                        <th scope='col' className='px-6 py-3 text-center'>
                          3 категория (руб.)
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {remorgs.map((remorg) => (
                        <tr key={remorg.id} className='hover:bg-gray-100'>
                          <td className='px-6 py-4 text-gray-900'>{remorg.nameRem}</td>
                          <td className='px-6 py-4 text-gray-500 text-center'>{remorg.rem1cat} руб.</td>
                          <td className='px-6 py-4 text-gray-500 text-center'>{remorg.rem2cat} руб.</td>
                          <td className='px-6 py-4 text-gray-500 text-center'>{remorg.rem3cat} руб.</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className='text-gray-700'>Извините, ничего не найдено</p>
              )}
            </div>

            <div className='text-gray-700 bg-gray-200 px-6 py-4 text-md shadow-sm mb-3 rounded-md'>
              <p>*Цены указаны без учета стоимости запчастей и расходных материалов. НДС не применяется.</p>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default Contacts;