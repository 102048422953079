import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ReactLoading from "react-loading";

const Proisv = () => {
  const navigate = useNavigate();
  const [producers, setProducers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const targetRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducers = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://lk.eq74.ru/api/cart/proisvall');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
        setProducers(data);
      } catch (error) {
        console.error("Не удалось загрузить список производителей:", error);
      } finally {
        setIsLoading(false);
      }

    };
    fetchProducers();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const filteredProducers = producers.filter(producer =>
    producer.nameProisv.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, minWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex w-full flex-col justify-center items-center gap-5">
        <div className='min-h-40 w-full flex items-center justify-between flex-wrap bg-gray-700 gap-10 p-10' ref={targetRef}>
          <div className="flex relative w-60 self-center">
            <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://static.tildacdn.com/tild6539-6237-4430-a232-623932323634/photo.png" />
          </div>
          <div className="flex relative flex-1 flex-col text-slate-50 items-center justify-center text-center flex-wrap gap-5">
            <div className="flex relative flex-1 text-center text-4xl">
              Заправка и восстановление картриджей
            </div>
            <div className="flex relative flex-1 text-center items-center justify-center text-sm">
              Знаем, как продлить жизнь вашим картриджам. Заправка и восстановление на профессиональном оборудовании. Качество и надежность.
            </div>
          </div>
        </div>
        <div className='flex w-full items-center gap-4 mb-4 pl-5 pr-5'>
          <button
            className="px-5 py-3 bg-slate-500 text-white rounded hover:bg-slate-600 transition duration-300"
            onClick={() => navigate(-1)}
          >
            Назад
          </button>
          <input
            type="text"
            placeholder="Поиск по наименованию..."
            className="px-5 py-3 m-4 w-full border-2 border-slate-300 rounded-lg"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='flex flex-wrap justify-center gap-4 px-5 mb-5'>
          {isLoading ? (
            <div className="flex items-center justify-center h-full w-full flex-col">
              <ReactLoading type="spinningBubbles" color="#103d77" height={100} width={100} />
              <h2>Загрузка, пожалуйста подождите...</h2>
            </div>
          ) : (
            <>
              {filteredProducers.map((producer, index) => (
                <div
                  key={index}
                  className="flex flex-col max-w-md bg-white shadow-xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl cursor-pointer"
                  onClick={() => navigate(`/cart/${producer._id}`)}
                >
                  <img
                    className="object-cover w-full h-48"
                    src={producer.urlProisv}
                    alt={producer.nameProisv}
                  />
                  <div className="flex flex-col items-start p-4">
                    <div className="flex-shrink-0 flex flex-row justify-center text-center items-center">

                      <h5 className="text-2xl font-semibold text-gray-900 mb-2">{producer.nameProisv}</h5>
                    </div>
                    <div className="ml-4 flex-grow">
                      <p className="text-gray-700 text-base leading-relaxed">{producer.opisanie}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>

          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Proisv;