// page/Contact.js
import React from 'react';
import { motion } from 'framer-motion';

const Contacts = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, minWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };
  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex relative min-h-30 w-full flex-col justify-center items-center gap-10">
        <div className='flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap bg-gray-700 gap-10 p-10'>
          <div className="flex relative w-60 self-center">
            <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://forzamk.crmgu.ru/wp-content/uploads/2018/07/icon_map.png" />
          </div>
          <div className="flex relative flex-1 flex-col text-slate-50 items-center justify-center text-center flex-wrap gap-5">
            <div className="flex relative flex-1 text-center text-4xl">
              НАШИ КОНТАКТЫ
            </div>
            <div className="flex relative flex-1 text-center items-center justify-center text-sm">
              Здесь вы найдёте всю необходимую информацию для связи с нами. Мы рады ответить на ваши вопросы и готовы сотрудничать!
            </div>
          </div>
        </div>

        <div className='flex relative min-h-96 w-full flex-wrap text-center items-center justify-center bg-gray-200 gap-10'>
          <div className='flex relative h-full min-w-96 bg-slate-100 text-center items-center justify-center flex-col'>
            <div className='flex relative flex-col p-4 text-center items-center justify-center'>
              <h2 className='text-xl font-bold'>Режим работы:</h2>
              <p className='text-lg'>Понедельник - Пятница: 9:00 - 19:00</p>
              <p className='text-lg'>Суббота: 10:00 - 16:00</p>
              <p className='ttext-lg font-semibold'>Воскресенье: Выходной</p>
            </div>
          </div>

          <div className='flex relative h-full flex-1 flex-wrap justify-around gap-10 md:p-10'>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-full p-5 gap-2 bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[5px]">
              <div className="flex relative self-center">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://www.freeiconspng.com/uploads/cell-icon-15.png" />
              </div>
              <div className="items-center justify-center text-center text-lg font-[700]">
                Телефон
              </div>
              <div className="items-center justify-center text-center text-lg font-[400]">
                Самый простой способ - позвонить!
              </div>
              <div className="items-center justify-center text-center text-lg font-[400] border-t-2">
                <a href="tel:+73512173337" className="border-[#2c3c72] border-dotted border-b-[2px] hover:border-solid hover:text-[#000]">
                  +7 (351) 217-33-37
                </a>
              </div>
            </div>

            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-full p-5 gap-2 bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[5px]">
              <div className="flex relative self-center">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://cdn.onlinewebfonts.com/svg/img_81706.png" />
              </div>
              <div className="items-center justify-center text-center text-lg font-[700]">
                Электронная почта
              </div>
              <div className="items-center justify-center text-center text-lg font-[400]">
                Старый проверенный способ - письмо!
              </div>
              <div className="flex relative flex-col items-center justify-center text-center text-lg font-[400] border-t-2">
                <a href="mailto:info@sc-7.com" className="border-[#2c3c72] border-dotted border-b-[2px] hover:border-solid hover:text-[#000]">
                  info@sc-7.com
                </a>
                <a href="mailto:eq174@yandex.ru" className="border-[#2c3c72] border-dotted border-b-[2px] hover:border-solid hover:text-[#000]">
                  eq174@yandex.ru
                </a>
              </div>
            </div>

            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-full p-5 gap-2 bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[5px]">
              <div className="flex relative self-center">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://mccormick.mit.edu/sites/default/files/images/mcc%20web%20icons%20location.png" />
              </div>
              <div className="items-center justify-center text-center text-lg font-[700]">
                Наш адрес
              </div>
              <div className="items-center justify-center text-center text-lg font-[400]">
                Посетите офис - здесь Вам с радостью помогут!
              </div>
              <div className="flex relative flex-col items-center justify-center text-center text-lg font-[400] border-t-2">
                г. Челябинск, проспект Победы, д. 290Б, оф. 105
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full relative hidden;">
          <a href="https://yandex.ru/maps/org/print_servis/77919933697/?utm_medium=mapframe&utm_source=maps" className="text-slate-900 text-lg absolute top-0">
            Принт-сервис
          </a>
          <a href="https://yandex.ru/maps/56/chelyabinsk/category/office_equipment_service_and_repair/184105560/?utm_medium=mapframe&utm_source=maps" className="text-slate-900 text-lg absolute top-2">
            Ремонт оргтехники в Челябинске
          </a>
          <iframe src="https://yandex.ru/map-widget/v1/?ll=61.355858%2C55.189839&mode=search&oid=77919933697&ol=biz&z=17.64" width="100%" height="400" frameborder="1" allowfullscreen="true" className="relative">

          </iframe>
        </div>

      </div>
    </motion.div>
  )
};

export default Contacts;