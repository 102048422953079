// page/Home.js
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './Home.css';

import Slider from "./slider2/slider";

const Home = () => {
  const navigate = useNavigate();
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  return (

    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex relative w-full flex-col justify-center items-center gap-10">
        <Slider />
        <h1 className='flex relative text-center text-4xl'>Какие улуги мы оказываем:</h1>

        <div className="bg-slate-200 pb-[70px] px-[0px] lg:pb-[20px] w-full mx-auto text-slate-900">
          <div className="flex flex-nowrap md:flex-wrap overflow-x-scroll rounded-[5px] pt-[80px] pb-[50px] px-[12px] gap-[30px] lg:overflow-x-hidden xl:justify-around">
            <div id="step-0" onClick={() => navigate("/orgteh")} className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://www.pngall.com/wp-content/uploads/2016/04/Printer-Free-PNG-Image.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ОРГТЕХНИКА
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Ремонт и обслуживание офисной техники
              </div>
            </div>
            <div id="step-0" onClick={() => navigate('/cart')} className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://i.siteapi.org/ABnuRdYeToTQE-uDX20IHNQ7eOs=/fit-in/1400x1000/center/top/s.siteapi.org/fd1d409764cc11d.ru/img/8devc55jnu8s0g8ockscgc08okcg4c" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                КАРТРИДЖИ
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Заправка и восстановление
              </div>
            </div>
            <div id="step-0" onClick={() => navigate('/chpu')} className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://i.pinimg.com/originals/78/7a/ea/787aea9a7684495d67b9188a9c69413b.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ПРОЭЛЕКТРОНИКА И ЧПУ
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Ремонт и обслуживание
              </div>
            </div>
            <div id="step-0" onClick={() => navigate("/kkt")} className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://webstockreview.net/images/market-clipart-merchant-14.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                КАССЫ
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Регистрация, замена ФН, подключение к ОФД и 1С, снятие с учёта
              </div>
            </div>
          </div>
        </div>

        <div className='flex relative w-full min-h-60 flex-wrap-reverse justify-around'>
          <div className='flex relative max-w-1/2 text-center items-center justify-center flex-wrap text-slate-900 p-10'>
            <ul className="flex flex-col relative list-image-checkmarker marker:text-2xl gap-2 text-left text-lg">
              <li>Работаем в сфере оказания услуг уже более 10 лет и имеем большой опыт</li>
              <li>Ремонтируем устройства всех известных производителей</li>
              <li>Профессиональные и сертифицированные мастера</li>
              <li>Ремонт оргтехники с выездом в офис или на дом</li>
              <li>Высокое качество обслуживания</li>
              <li>У нас всегда низкие цены</li>
              <li>Гарантия качества</li>
            </ul>
          </div>
          <div className='flex relative w-96 bg-slate-700 text-center items-center justify-center flex-wrap text-slate-50 text-2xl p-10'>
            Почему стоит проводить техническое обслуживание оргтехники у нас?
          </div>
        </div>

        <h1 className='flex relative text-center text-4xl'>Наши клиенты:</h1>
        <div className="pb-[70px] px-[0px] lg:pb-[20px] w-full mx-auto text-slate-900">
          <div className="flex flex-nowrap md:flex-wrap overflow-x-scroll rounded-[5px] pt-[80px] pb-[50px] px-[12px] gap-[30px] lg:overflow-x-hidden xl:justify-around">
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://api.rbsmi.ru/attachments/b7c4ecf4f84dee1f983bf1a4cbed165d02a8e832/store/crop/0/0/982/1024/1600/0/0/14148ad88d568c6a8747f4c8484423430e5c8b71b8d31153199b8ba2aaa0/38148b42820b1145e3d0531978394939.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ИФНС
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Федеральная налоговая служба
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src={require('./KB.png')} />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Красное & Белое
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Cеть магазинов, осуществляющая продажу алкоголя различных видов
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://fgup-ohrana.ru/local/client/images/logo.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ФГУП "Охрана" Росгвардии
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Осуществляет весь спектр охранных услуг на всей территории РФ
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Emblem_of_the_Office_of_the_Prosecutor_General_of_Russia.svg/640px-Emblem_of_the_Office_of_the_Prosecutor_General_of_Russia.svg.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Прокуратура
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Надзор за соблюдением Конституции Российской Федерации
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src={require('./logoNew.png')} />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Диана
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Сеть химчисток и прачечных в г. Челябинске
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://sbrosdolg-lfsp.ru/img/a83opfe32377/banklogo-205.png?231129013029" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Альфа-Банк
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Крупнейший частный банк, занимающий четвёртое место по размеру активов
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://mfc-74.ru/bitrix/templates/mfc/img/logo-full.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ОГАУ МФЦ
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Многофункциональные центры предоставления государственных и муниципальных услуг
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://www.kdez74.ru/Images/DEZLogo.gif" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ДЕЗ Калининского района
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Оказывает услуги по эксплуатации и содержанию жилой и нежилой недвижимости
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://static.rustore.ru/apk/2063487151/content/ICON/f7f2ab5e-df3f-420b-a292-c8c35c27eae6.png" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Торговый дом ММК
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Является уполномоченным поставщиком продукции Группы ПАО «ММК»
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://roscarservis.ru/local/templates/roscar/img/logo-cut.svg" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Роскар
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Услуги обслуживания, диагностика и ремонт автомобилей
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://sun9-57.userapi.com/impg/PO1RJonZMLoEdoDDGRS6-7DFUBDOm4TMFPdbdQ/l-Vte8jHxoU.jpg?size=867x867&quality=95&sign=50fcb9d1269f1306026657c4715335c4&type=album" />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                ГЖИ г. Челябинск
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Главное управление Государственная жилищная инспекция Челябинской области
              </div>
            </div>
            <div id="step-0" className="flex flex-col w-[270px] min-w-[228px] h-[277px] bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]">
              <div className="absolute self-center top-[-20px]">
                <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src={require('./logo.png')} />
              </div>
              <div className="pl-[40px] pr-[10px] pb-[10px] text-[16px] font-[700]">
                Спецпошив
              </div>
              <div className="px-[40px] pb-[45px] text-[13px] font-[400]">
                Спецодежда, спецобувь, средства индивидуальной защиты
              </div>
            </div>
          </div>
        </div>

      </div>
    </motion.div>
  );
};

export default Home;