import React from 'react';
import { useNavigate } from 'react-router-dom';
import './navigation.css';

const Navigation = () => {
    const navigate = useNavigate();

    return (
        <div className="flex relative w-full flex-1">
            <div
                className="flex relative gap-5 flex-1 text-black flex-wrap">
                <button className="flex-1 h-10 min-w-40 rounded border-2 border-slate-900 text-slate-900 transition duration-150 ease-in-out hover:border-primary-600 hover:scale-110 hover:bg-neutral-900 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0" onClick={() => navigate("/")}>
                    <span className='nav-link'>Главная</span>
                </button>
                <div className="dropdown flex-1 min-w-40">
                    <button className="flex-1 h-10 w-full rounded border-2 border-slate-900 text-slate-900 transition duration-150 ease-in-out hover:border-primary-600 hover:scale-110 hover:bg-neutral-900 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0">
                        <span className='nav-link'> Услуги</span>
                    </button>
                    <div className="dropdown-content bg-gray-700 text-white">
                        <button className="Button-nav" onClick={() => navigate("/orgteh")}>Ремонт оргтехники</button>
                        <button className="Button-nav" onClick={() => navigate('/cart')}>Заправка и восстановление картриджей</button>
                        <button className="Button-nav" onClick={() => navigate('/chpu')}>Ремонт Помэлектроники и ЧПУ станков </button>
                        <button className="Button-nav" onClick={() => navigate("/kkt")}>Решения для касс</button>
                    </div>
                </div>
                <div className="dropdown flex-1 min-w-40">
                    <button className="flex-1 h-10 w-full rounded border-2 border-slate-900 text-slate-900 transition duration-150 ease-in-out hover:border-primary-600 hover:scale-110 hover:bg-neutral-900 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0">
                        <span className='nav-link'> О нас</span>
                    </button>
                    <div className="dropdown-content bg-gray-700 text-white">
                        <button className="Button-nav" onClick={() => navigate("/contacts")}>Контакты</button>
                        <button className="Button-nav" onClick={() => navigate("/onas")}>Наша команда</button>
                        <button className="Button-nav" onClick={() => navigate("/otziv")}>Отзывы</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigation;