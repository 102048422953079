import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactLoading from "react-loading";

const CartridgesList = () => {
    const navigate = useNavigate();
    const { brandId } = useParams();
    const [cartridges, setCartridges] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const targetRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCartridges = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://lk.eq74.ru/api/cart/${brandId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
                const data = await response.json();
                setCartridges(data);
            } catch (error) {
                console.error("Failed to load cartridges:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCartridges();
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [brandId]);

    const filteredCartridges = searchTerm
        ? cartridges.filter(cartridge => cartridge.nameCart && cartridge.nameCart.toLowerCase().includes(searchTerm.toLowerCase()))
        : cartridges;

    const pageMotion = {
        initial: { opacity: 0, x: 50, maxWidth: '90%' },
        animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
    };

    return (
        <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
            <div className='flex flex-col w-full min-h-screen gap-4 pb-5'>
                <div className='flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap bg-gray-700 gap-10 p-10' ref={targetRef}>
                    <div className="flex relative w-60 self-center">
                        <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src="https://static.tildacdn.com/tild6539-6237-4430-a232-623932323634/photo.png" />
                    </div>
                    <div className="flex relative flex-1 flex-col text-slate-50 items-center justify-center text-center flex-wrap gap-5">
                        <div className="flex relative flex-1 text-center text-4xl">
                            Заправка и восстановление картриджей
                        </div>
                        <div className="flex relative flex-1 text-center items-center justify-center text-sm">
                            Знаем, как продлить жизнь вашим картриджам. Заправка и восстановление на профессиональном оборудовании. Качество и надежность.
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-4 mb-4 pl-6 pr-6'>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                        onClick={() => navigate(-1)}
                    >
                        Назад
                    </button>
                    <input
                        type="text"
                        placeholder="Введите модель картриджа для поиска..."
                        className="flex-1 p-2 border rounded"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className='flex relative pl-4 pr-4 w-full gap-2 flex-wrap justify-around'>
                    {isLoading ? (
                        <div className="flex items-center justify-center h-full w-full flex-col">
                            <ReactLoading type="spinningBubbles" color="#103d77" height={100} width={100} />
                            <h2>Загрузка, пожалуйста подождите...</h2>
                        </div>
                    ) : (
                        <>
  {filteredCartridges.map((cartridge, index) => (
    <div
      key={index}
      className="relative flex flex-col w-full max-w-sm mx-auto bg-white rounded-lg border border-gray-300 shadow-md overflow-hidden transition-transform transform hover:scale-105 duration-300"
    >
      <div className="flex items-center p-4 bg-gray-800 text-white">
        <img
          className="w-28 h-28 rounded-lg object-cover"
          alt={cartridge.nameCart}
          loading="lazy"
          src={require('./no-photo.jpg')}
        />
        <div className="ml-4 flex flex-col justify-center">
          <h2 className="text-xl font-semibold">{cartridge.nameCart}</h2>
          <p className="text-sm mt-1 text-gray-300">Ресурс картриджа: {cartridge.resurs} стр.</p>
        </div>
      </div>
      <div className="p-4">
        <h3 className="text-md font-semibold text-gray-700 mb-3">Стоимость работ:</h3>
        {cartridge.cenaZapr && (
          <div className="flex justify-between py-2 border-b border-gray-200">
            <span className="text-sm font-medium">Заправка:</span>
            <span className="text-sm text-gray-600">{cartridge.cenaZapr} руб.</span>
          </div>
        )}
        {cartridge.cenaChip && (
          <div className="flex justify-between py-2 border-b border-gray-200">
            <span className="text-sm font-medium">Замена чипа:</span>
            <span className="text-sm text-gray-600">{cartridge.cenaChip} руб.</span>
          </div>
        )}
        {cartridge.cenaFoto && (
          <div className="flex justify-between py-2 border-b border-gray-200">
            <span className="text-sm font-medium">Замена фотобарабана:</span>
            <span className="text-sm text-gray-600">{cartridge.cenaFoto} руб.</span>
          </div>
        )}
        {cartridge.cenaLezv && (
          <div className="flex justify-between py-2 border-b border-gray-200">
            <span className="text-sm font-medium">Замена ракеля:</span>
            <span className="text-sm text-gray-600">{cartridge.cenaLezv} руб.</span>
          </div>
        )}
        {cartridge.cenaRolica && (
          <div className="flex justify-between py-2 border-b border-gray-200">
            <span className="text-sm font-medium">Замена ролика заряда:</span>
            <span className="text-sm text-gray-600">{cartridge.cenaRolica} руб.</span>
          </div>
        )}
        <div className="flex justify-between py-2 mt-4 bg-gray-100 border-t border-gray-200">
          <span className="text-sm font-semibold text-gray-700">Стоимость нового картриджа:</span>
          <span className="text-sm text-gray-700">{cartridge.cenaCart} руб.</span>
        </div>
      </div>
    </div>
  ))}
</>


                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default CartridgesList;