import React from 'react';

function EquipmentCard({ name, description, imageUrl }) {
  return (
    <div className="max-w-sm rounded-xl overflow-hidden shadow-lg m-4 transition-transform transform hover:scale-105 hover:shadow-2xl bg-white border border-gray-200">
      <img className="w-full object-cover h-64" src={imageUrl} alt={name} />
      <div className="px-6 py-4">
        <div className="font-semibold text-2xl text-gray-800 mb-3">{name}</div>
        <p className="text-gray-600 text-base leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
}

export default EquipmentCard;
