// page/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

const Footer = () => {
    const navigate = useNavigate();
    const pageMotion = {
        initial: { opacity: 0, x: 50, maxWidth: '90%' },
        animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
    };

    return (

        <footer className="bg-gray-700 w-full text-slate-50">
            <div className="max-w-7xl mx-auto py-3 grid grid-cols-1 gap-10 px-5 lg:grid-cols-5">
                <div className="flex flex-row items-center gap-5 lg:col-span-2">
                    <div className="w-[120px] cursor-pointer" onClick={() => navigate("/")}>
                        <a>
                            <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src={require('./icon.png')} />
                        </a>
                    </div>
                    <div className="flex flex-col [&amp;>*]:text-[13px] [&amp;>*]:uppercase">
                        <div className="font-[600] leading-[14px] pb-[20px]">
                            Группа компаний "Принт-Сервис<br /> ООО "Инжиниринговая компания"
                        </div>
                        <div className="font-[500] text-[#adadad]">
                            © Принт-Сервис 2024
                        </div>
                    </div>
                </div>
                <div className="text-[12px] font-[600] text-slate-100 uppercase hidden lg:block">
                    Навигация
                    <div className="w-[26px] h-[1px] bg-[#C8C8C8] mt-[20px]">
                    </div>
                </div>
                <div className="text-[12px] font-[600] text-slate-100 uppercase hidden lg:block">
                    Информация
                    <div className="w-[26px] h-[1px] bg-[#C8C8C8] mt-[20px]">
                    </div>
                </div>
                <div className="text-[12px] font-[600] text-slate-100 uppercase hidden lg:block">
                    Контакты
                    <div className="w-[26px] h-[1px] bg-[#C8C8C8] mt-[20px]">
                    </div>
                </div>
                <div className="text-sm leading-[24px] md:max-w-[500px] lg:col-span-2 lg:pr-[70px]">
                    Группа компаний "Принт-сервис" предлагает услуги по заправке картриджей, ремонту оргтехники, компьютеров, ноутбуков, телефонов, игровых приставок.
                </div>
                <div className="flex flex-col gap-5 text-sm [&amp;>*]:text-[#2F2F2F]">
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/orgteh")}>
                        Ремонт оргтехники
                    </a>
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/cart")}>
                        Заправка и восстановление картриджей
                    </a>
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/chpu")}>
                        Ремонт Промэлектроники и ЧПУ станков
                    </a>
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/kkt")}>
                        Решения для кассы
                    </a>
                </div>
                <div className="flex flex-col gap-5 text-sm [&amp;>*]:text-[#2F2F2F]">
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/onas")}>
                        Наша команда
                    </a>
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/contacts")}>
                        Контакты
                    </a>
                    <a className="hover:underline cursor-pointer" onClick={() => navigate("/otziv")}>
                        Отзывы о нас
                    </a>
                </div>
                <div className="">
                    <div className="text-[18px] font-[600] text-slate-50 pb-[10px]">
                        <a href="tel:+73512173337" className="border-[#2c3c72] border-dotted border-b-[2px] hover:border-solid hover:text-[#000]">
                            +7 (351) 217-33-37
                        </a>
                    </div>
                    <div className="flex flex-col text-[14px] font-[600] text-slate-50">
                        <a href="mailto:info@sc-7.com" className="border-[#2c3c72] border-dotted border-b-[2px] hover:border-solid hover:text-[#000]">
                            info@sc-7.com
                        </a>
                        <a href="mailto:eq174@yandex.ru" className="border-[#2c3c72] border-dotted border-b-[2px] hover:border-solid hover:text-[#000]">
                            eq174@yandex.ru
                        </a>
                    </div>
                    <div className="flex flex-col text-[14px] font-[600] text-slate-50">
                        <YMInitializer accounts={[97490106]} />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;