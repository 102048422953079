import React from 'react';
import { Routes, Route } from "react-router-dom";
import {AnimatePresence} from "framer-motion";  
import { useLocation } from 'react-router-dom';

// Pages
import HomePage from './Page/homepage/Home';
import ContactPage from './Page/сontacts/Contacts';
import KKT from './Page/kassi/kassi';
import RemOrg from './Page/remorg/remorg';
import Onas from './Page/onas/onas';
import Otziv from './Page/otziv/otziv';
import Dev from './Page/devs/Devs';
import Cartr from './Page/cartr/proisv';
import Chpu from './Page/chpu/chpu';
import CartidgesList from './Page/cartr/CartidgesList';

const AnimatedRoutes = () => {
      const location = useLocation();
  
      return (
          <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path="contacts" element={<ContactPage />} />
            <Route path="kkt" element={<KKT />} />
            <Route path="orgteh" element={<RemOrg />} />
            <Route path="onas" element={<Onas />} />
            <Route path="otziv" element={<Otziv />} />
            <Route path="dev" element={<Dev />} />
            <Route path="cart" element={<Cartr />} />
            <Route path="/cart/:brandId" element={<CartidgesList />} />
            <Route path="chpu" element={<Chpu />} />
          </Routes>
        </AnimatePresence>
      );
    };
  
    export default AnimatedRoutes;