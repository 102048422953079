// page/Home.js
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import GearsAnimation from './GearsAnimation'; // Предполагается, что компонент с анимацией шестеренок находится в файле GearsAnimation.js

const Devops = () => {
  const navigate = useNavigate();
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex relative w-full flex-col justify-center items-center gap-10">
        <div className="flex relative items-center justify-center flex-col w-[80%] text-center p-10">
          <GearsAnimation />
          <h1 className="text-3xl font-bold mb-4">Раздел находится в разработке!</h1>
          <p className="text-xl">Мы постоянно работаем над улучшением нашего сайта и добавлением новой информации. Пожалуйста, проявите терпение, так как доступность раздела может быть ограничена.</p>
          <button
            className="mt-6 px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
            onClick={() => navigate(-1)}
          >
            Назад
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default Devops;